/* eslint-disable import/no-cycle */
import {IPropsInjectedByViewerScript} from '../../types/galleryTypes';

export interface FilterAnnouncerData {
  shouldAnnounceFilters: boolean;
  setShouldAnnounceFilters: (shouldAnnounce: boolean) => void;
}

export class FiltersAnnouncerService {
  private shouldAnnounceFilters: boolean = false;
  private readonly updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void;

  constructor({updateComponent}: {updateComponent: (props: Partial<IPropsInjectedByViewerScript>) => void}) {
    this.shouldAnnounceFilters = false;
    this.updateComponent = updateComponent;
  }

  public setShouldAnnounceFilters(shouldAnnounce: boolean) {
    this.shouldAnnounceFilters = shouldAnnounce;
    if (!shouldAnnounce) {
      this.updateComponent({useFiltersAnnouncer: this.getProps()});
    }
  }

  public getProps(): FilterAnnouncerData {
    return {
      shouldAnnounceFilters: this.shouldAnnounceFilters,
      setShouldAnnounceFilters: this.setShouldAnnounceFilters.bind(this),
    };
  }
}
