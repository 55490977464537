import type {IFilterOption} from '../../types/galleryTypes';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {BASE_TICKS} from './constants';

export const generatePriceFilterOptions = (
  min: number,
  max: number,
  formatCurrency: ControllerFlowAPI['formatCurrency'],
  currency: string
): IFilterOption[] => {
  const delta = max - min;
  const ticksAmount = delta <= BASE_TICKS ? delta + 1 : BASE_TICKS; // delta+1 to include max as a tick
  const stepSize = Math.max(Math.round(delta / ticksAmount), 1);

  const ticks = [];
  for (let i = 0; i < ticksAmount - 1; i++) {
    ticks.push(Math.round(min + stepSize * i));
  }
  ticks.push(max); // push max separately to avoid number larger than max

  return ticks.map((price) => ({
    key: price.toString(),
    value: formatCurrency({value: price, currency}) as string,
  }));
};
