/* eslint-disable import/no-cycle */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {GetFiltersQuery} from '../graphql/queries-schema';
import {Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {query as getFiltersQueryString} from '../graphql/getFilters.graphql';
import {FilterTypeForFetch} from '../constants';
import {IFiltersMetadataApi} from './IFiltersMetadataApi';

export class FiltersApi implements IFiltersMetadataApi {
  constructor(private readonly siteStore: SiteStore) {}

  public async getFilters(
    filterTypeDTOs: FilterTypeForFetch[],
    mainCollectionId: string
  ): Promise<GetFiltersQuery['catalog']['filters']> {
    const query = {
      query: getFiltersQueryString,
      variables: {enabledFilters: filterTypeDTOs, mainCategory: mainCollectionId},
      source: 'WixStoresWebClient',
      operationName: 'getFilters',
    };

    const filtersRes = (await this.siteStore.tryGetGqlAndFallbackToPost(
      this.siteStore.resolveAbsoluteUrl(`/${Topology.STOREFRONT_GRAPHQL_URL}`),
      query
    )) as {
      data: GetFiltersQuery;
    };

    return filtersRes.data.catalog.filters;
  }
}
