/* eslint-disable import/no-cycle */
import {FilterEqOperation, FilterType, IFilterDTO, IFilterModel, IFilterOption} from '../../types/galleryTypes';
import {CollectionFilterModel} from '../../models/CollectionFilterModel';
import {MultiCollectionFilterModel} from '../../models/MultiCollectionFilterModel';
import _ from 'lodash';
import {FilterConfigsService} from './FilterConfigsService';
import {ExternalCollectionsFilterWrapper} from '../../types/filters';

export const FILTER_FIELD = 'categories';

export class CollectionFiltersService {
  private readonly usingMultiCollectionFilters: boolean;

  constructor(
    private mainCollectionId: string,
    private readonly filterConfigsService: FilterConfigsService,
    usingMultiCollectionFilters?: boolean
  ) {
    this.usingMultiCollectionFilters = usingMultiCollectionFilters;
  }

  public createCollectionFilterModel(filterOptions: IFilterOption[]): IFilterModel {
    const enabledFilterOptions = this.getEnabledCollectionFilterOptions(filterOptions);
    const title = this.filterConfigsService.getFilterConfigTitle(FilterType.COLLECTION);
    return this.usingMultiCollectionFilters
      ? new MultiCollectionFilterModel(FilterType.COLLECTION, title, enabledFilterOptions)
      : new CollectionFilterModel(FilterType.COLLECTION, title, enabledFilterOptions, this.mainCollectionId);
  }

  public createCustomCollectionFilterModels(filterOptions: IFilterOption[]): MultiCollectionFilterModel[] {
    const customCollectionFilterConfigs = this.filterConfigsService.getCustomCollectionFilterConfigs();

    return customCollectionFilterConfigs.map((filterConfig) => {
      const enabledFilterOptions = this.filterConfigsService.getEnabledFilterOptionsFromFilterConfig(
        filterOptions,
        filterConfig
      );
      return new MultiCollectionFilterModel(
        FilterType.CUSTOM_COLLECTION,
        filterConfig.filterTitle,
        enabledFilterOptions
      );
    });
  }

  public toDTOs(
    collectionFilterModels: (MultiCollectionFilterModel | CollectionFilterModel)[],
    customCollectionFilterModels: MultiCollectionFilterModel[]
  ): IFilterDTO[] {
    const dtos: IFilterDTO[] = [];
    const selectedCollectionFilters = this.getSelectedCollectionFilters(collectionFilterModels);
    const selectedCustomCollectionFilters = this.getSelectedCustomCollectionFilters(customCollectionFilterModels);

    selectedCollectionFilters.forEach((selectedFilter) => {
      dtos.push(selectedFilter.toDTO());
    });

    selectedCustomCollectionFilters.forEach((selectedFilter: MultiCollectionFilterModel) => {
      dtos.push(selectedFilter.toDTO());
    });

    return dtos;
  }

  public toExternalDTO(collectionFilterModels: CollectionFilterModel[]): ExternalCollectionsFilterWrapper {
    const selectedCollectionFilters = this.getSelectedCollectionFilters(collectionFilterModels);
    const collections = selectedCollectionFilters.flatMap((selectedFilter: CollectionFilterModel) =>
      selectedFilter.toExternalDTO()
    );

    if (collections.length > 0) {
      return {
        collections: {
          $any: collections,
        },
      };
    }
  }

  public getEnabledCollectionFilterOptions(filterOptions: IFilterOption[]): IFilterOption[] {
    let result = _.cloneDeep(filterOptions);
    const collectionFilterConfigs = this.filterConfigsService.getCollectionFilterConfigs();

    if (collectionFilterConfigs.length !== 0) {
      result = this.filterConfigsService.getEnabledFilterOptionsFromFilterConfig(
        filterOptions,
        collectionFilterConfigs[0]
      );
    }

    if (this.usingMultiCollectionFilters) {
      result = result.filter((c) => c.key !== this.mainCollectionId);
    }

    return result;
  }

  public getSelectedCustomCollectionFilters(
    collectionFilterModels: MultiCollectionFilterModel[]
  ): MultiCollectionFilterModel[] {
    return collectionFilterModels.filter((fm) => fm.hasActiveOptions());
  }

  public shouldSpecificCollectionQuery(filter: IFilterDTO): boolean {
    return filter.field === FILTER_FIELD && filter.op === FilterEqOperation.IN_ALL && filter.values.length === 1;
  }

  private getSelectedCollectionFilters(
    collectionFilterModels: (MultiCollectionFilterModel | CollectionFilterModel)[]
  ): IFilterModel[] {
    return collectionFilterModels
      .filter((fm) => fm.hasActiveOptions() && fm.activeOptions !== this.mainCollectionId)
      .map((fm) => {
        const filter = _.cloneDeep(fm);
        filter.activeOptions = (
          this.usingMultiCollectionFilters ? [...filter.activeOptions] : [filter.activeOptions]
        ) as string[];
        return filter;
      });
  }

  public setMainCollectionId = (mainCollectionId: string) => {
    this.mainCollectionId = mainCollectionId;
  };
}
