import {StyleParamType, wixFontParam} from '@wix/tpa-settings';
import {GridType} from '../types/galleryTypes';
import {AutoGridTemplateRepeatOptions} from '../constants';
import {IStylesParams} from './types';

// updated style params overrides for newer widgets
export const updatedStyleParamsOverrides: Partial<IStylesParams> = {
  gallery_gridType: {
    type: StyleParamType.Number,
    getDefaultValue: () => GridType.AUTO,
  },
  gallery_filterOptionsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  showQuickView: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  gallery_addToCartButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  gallery_ribbonSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 4,
  },
  gallery_ribbonTopPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 4,
  },
  gallery_autoGridTemplateRepeatOption: {
    type: StyleParamType.Font,
    getDefaultValue: () => ({
      fontStyleParam: false,
      value: AutoGridTemplateRepeatOptions.AUTO_FILL,
    }),
  },
};
