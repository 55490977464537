import {SectionUrl} from '@wix/wixstores-client-storefront-sdk/dist/src/viewer-script/site-store/SiteStore';
import {ReadOnlyExperiments} from '@wix/wix-experiments';
import {Experiments} from '../../constants';

export function getProductPageSectionUrl(
  sectionUrl: SectionUrl,
  baseUrl: string,
  experiments: ReadOnlyExperiments
): string {
  if (experiments.enabled(Experiments.UsePrefixForRouterNavigationToProductPage) && sectionUrl.prefix) {
    return `${baseUrl}/${sectionUrl.prefix}`;
  }

  return sectionUrl.url;
}
