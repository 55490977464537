/* eslint-disable import/no-cycle */
import _ from 'lodash';
import {IStylesParamsValues} from '../../styleParams/types';
import {StoresWidgetID} from '@wix/wixstores-client-core/dist/es/src/constants';
import {categoryPageSortOptions, gallerySortOptions, searchPageSortOptions} from './constants';
import {ISortingOption} from '../../types/sorting';

export class SortService {
  private readonly sortingOptions: ISortingOption[];
  private visibleSortingOptions: ISortingOption[];
  private defaultSortingOption: ISortingOption;
  private selectedSort: ISortingOption;

  constructor(private readonly storeWidgetId: StoresWidgetID, styleParams: IStylesParamsValues) {
    this.sortingOptions = this.getSortingOptionsByWidgetId();
    this.updateVisibleSortingOptions(styleParams);
    this.selectedSort = this.getDefaultSortingOption();
  }

  public updateVisibleSortingOptions(styles: IStylesParamsValues): ISortingOption[] {
    this.visibleSortingOptions = this.sortingOptions.filter((o) => styles[o.settingsShouldDisplayKey] !== false);
    return this.visibleSortingOptions;
  }

  public getDefaultSortingOption = (): ISortingOption => {
    return this.defaultSortingOption ?? this.visibleSortingOptions[0];
  };

  public getSelectedSort = (): ISortingOption => {
    return this.selectedSort;
  };

  public setDefaultSortBySettings = (settingsShouldDisplayKey): ISortingOption => {
    this.selectedSort = _.find(this.sortingOptions, ['settingsShouldDisplayKey', settingsShouldDisplayKey]);
    this.defaultSortingOption = this.selectedSort;
    return this.selectedSort;
  };

  public setSelectedSort = (sortId: string): ISortingOption => {
    this.selectedSort = _.find(this.sortingOptions, ['id', sortId]);
    return this.selectedSort;
  };

  public getSort = (sortId: string): ISortingOption => {
    if (sortId === 'Default') {
      return _.find(this.sortingOptions, ['id', 'default']);
    } else {
      return _.find(this.sortingOptions, ['id', sortId]);
    }
  };

  private getSortingOptionsByWidgetId(): ISortingOption[] {
    if (this.storeWidgetId === StoresWidgetID.CATEGORY_PAGE) {
      return categoryPageSortOptions;
    } else if (this.storeWidgetId === StoresWidgetID.SEARCH_RESULTS_GALLERY) {
      return searchPageSortOptions;
    }

    return gallerySortOptions;
  }
}
