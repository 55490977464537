import {GetFiltersQuery} from '../graphql/queries-schema';
import {IFiltersMetadataApi} from './IFiltersMetadataApi';
import {FilterTypeForFetch} from '../constants';

export class FiltersExternalApi implements IFiltersMetadataApi {
  constructor(private readonly getFiltersMetadata: () => Promise<GetFiltersQuery['catalog']['filters']>) {}

  public async getFilters(filterTypeDTOs: FilterTypeForFetch[]): Promise<GetFiltersQuery['catalog']['filters']> {
    const filtersMetadataRes = await this.getFiltersMetadata();
    return filtersMetadataRes?.filter((f) => filterTypeDTOs.find((dto) => dto === f.filterType));
  }
}
