import {ISortingOption, SortingDirection} from '../../types/sorting';

export const enum SortOptionsIds {
  Default = 'default',
  Recommended = 'recommended',
  MostRelevant = 'most_relevant',
  Newest = 'newest',
  PriceAscending = 'price_ascending',
  PriceDescending = 'price_descending',
  NameAscending = 'name_ascending',
  NameDescending = 'name_descending',
}

export const commonSortOptions: ISortingOption[] = [
  {
    field: 'creationDate',
    direction: SortingDirection.Descending,
    id: SortOptionsIds.Newest,
    titleKey: 'sortOptionNewestText',
    settingsShouldDisplayKey: 'gallerySortNewest',
  },
  {
    field: 'comparePrice',
    direction: SortingDirection.Ascending,
    id: SortOptionsIds.PriceAscending,
    titleKey: 'sortOptionLowPriceText',
    settingsShouldDisplayKey: 'gallerySortPriceAsc',
  },
  {
    field: 'comparePrice',
    direction: SortingDirection.Descending,
    id: SortOptionsIds.PriceDescending,
    titleKey: 'sortOptionHighPriceText',
    settingsShouldDisplayKey: 'gallerySortPriceDes',
  },
  {
    field: 'name',
    direction: SortingDirection.Ascending,
    id: SortOptionsIds.NameAscending,
    titleKey: 'sortOptionNameAZText',
    settingsShouldDisplayKey: 'gallerySortNameAsc',
  },
  {
    field: 'name',
    direction: SortingDirection.Descending,
    id: SortOptionsIds.NameDescending,
    titleKey: 'sortOptionNameZAText',
    settingsShouldDisplayKey: 'gallerySortNameDes',
  },
];

export const gallerySortOptions: ISortingOption[] = [
  {
    field: '',
    id: SortOptionsIds.Default,
    titleKey: 'sortTitleText',
  },
  ...commonSortOptions,
];

export const categoryPageSortOptions: ISortingOption[] = [
  {
    field: '',
    id: SortOptionsIds.Recommended,
    titleKey: 'sortRecommendedText',
    settingsShouldDisplayKey: 'gallerySortRecommended',
  },
  ...commonSortOptions,
];

export const searchPageSortOptions: ISortingOption[] = [
  {
    field: '',
    id: SortOptionsIds.MostRelevant,
    titleKey: 'sortMostRelevantText',
    settingsShouldDisplayKey: 'gallerySortRecommended',
  },
  ...commonSortOptions,
];
